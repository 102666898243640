.new-badge {
    position: absolute;
    border-radius: 50%;
    top: -10px;
    transform: translateX(20%);
    .ant-badge-count {
      background-color: #098e50;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
    }
  }