.preview-wrapper {
  width: 64px;
  height: 48px;
  min-width: 64px;
  min-height: 48px;
}

.preview-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
